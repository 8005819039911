import type { PropertyCards, PropertyFieldProps } from '@/types/Properties'
import { transformIsInToCheckboxOptionsArray, transformIsInToSelectOptionsArray } from '~~/src/helpers/properties'

export const addFieldProps = (field: PropertyFieldProps, initialValues: keyof PropertyCards) => {
    switch (field.fieldType) {
    case 'text':
    case 'textarea':
        field.fieldProps = { maxCharacterCount: field.maxCharacterCount }
        break
    case 'dropdown':
        if (initialValues) { field.fieldProps = { options: transformIsInToSelectOptionsArray(field.isIn), activeOption: initialValues[field.fieldKey] } }
        break
    case 'multi-select':
        if (initialValues) { field.fieldProps = { items: transformIsInToCheckboxOptionsArray(field.isIn, initialValues[field.fieldKey] as string[] || []), max: field.maxMultiSelectCount } }
        break
    case 'integer':
    case 'decimal':
    case 'currency':
        field.fieldProps = { type: 'number', min: field.minimalNumber }
        break
    case 'label':
        field.editable = false
    }
}
export const addFieldModalEdit = (field: PropertyFieldProps) => {
    if (['text-dimensions', 'multi-select', 'textarea'].includes(field.fieldType)) {
        field.withModalEdit = true
    }
}
